import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchGrids, clearUser } from './store';
import { createClient } from '@supabase/supabase-js';
import { ReactComponent as DynamicDraftsLogo } from './DynamicDraftsLogo.svg';

const supabase = createClient('https://vpwkvuwpssblzjaxpjbr.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZwd2t2dXdwc3NibHpqYXhwamJyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMwOTI2NTcsImV4cCI6MjAzODY2ODY1N30.S_Rg5WmFbpIe7-pzhD7RtFzQ69HVHFhVj_4Wg92bk_c');

const Dashboard = () => {
    const user = useSelector(state => state.auth.user);
    const grids = useSelector(state => state.grid.grids);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            dispatch(fetchGrids(user.id));
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate, user]);

    const handleLogout = async () => {
        try {
            await supabase.auth.signOut();
            dispatch(clearUser());
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
            alert('Failed to log out. Please try again.');
        }
    };

    if (!user) {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f3f4f6'}}>
            <div style={{fontSize: '1.5rem', fontWeight: 600, color: '#1f2937'}}>Loading...</div>
        </div>;
    }

    const buttonStyle = {
        display: 'block',
        padding: '0.5rem 1rem',
        borderRadius: '0.25rem',
        backgroundColor: '#b0c4de',
        color: '#1f2937',
        fontFamily: "'Arial', sans-serif",
        fontWeight: '400',
        textDecoration: 'none',
        transition: 'all 0.3s ease',
        transform: 'translateY(0)',
        boxShadow: 'none'
    };

    const buttonHoverStyle = {
        backgroundColor: '#9fb6cd',
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    };

    const handleMouseEnter = (e) => {
        Object.assign(e.currentTarget.style, buttonHoverStyle);
    };

    const handleMouseLeave = (e) => {
        Object.assign(e.currentTarget.style, buttonStyle);
    };

    const gridItemStyle = {
        display: 'block',
        backgroundColor: '#f9fafb',
        padding: '1.5rem',
        borderRadius: '0.5rem',
        transition: 'all 0.3s ease',
        border: '1px solid #e5e7eb',
        transform: 'translateY(0)',
        boxShadow: 'none',
        textDecoration: 'none'
    };

    const gridItemHoverStyle = {
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        transform: 'translateY(-2px)'
    };

    return (
        <div style={{display: 'flex', height: '100vh', backgroundColor: '#f3f4f6'}}>
            <aside style={{width: '16rem', background: 'linear-gradient(to bottom, #4338ca, #3730a3)', color: 'white'}}>
                <div style={{padding: '1.5rem'}}>
                    <Link to="/dashboard" style={{display: 'block', marginBottom: '1.5rem'}}>
                        <DynamicDraftsLogo style={{width: '100%', height: 'auto'}} />
                    </Link>
                    <nav>
                        <ul style={{display: 'flex', flexDirection: 'column', gap: '0.75rem', listStyleType: 'none', padding: 0, margin: 0}}>
                            <li>
                                <Link to="/cad" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    New Grid
                                </Link>
                            </li>
                            <li>
                                <Link to="/account" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    Account
                                </Link>
                            </li>
                            <li>
                                <Link to="/subscription" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    Subscription
                                </Link>
                            </li>
                            {user.role === 'admin' && (
                                <li>
                                    <Link to="/admin" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        Admin Console
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </aside>
            <main style={{flex: 1, overflowY: 'auto'}}>
                <div style={{maxWidth: '1200px', margin: '0 auto', padding: '2rem 1.5rem'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2rem'}}>
                        <h1 style={{fontSize: '1.875rem', fontWeight: 'bold', color: '#1f2937'}}>Welcome, {user.email}</h1>
                        <button 
                            onClick={handleLogout} 
                            style={{
                                backgroundColor: '#ef4444', 
                                color: 'white', 
                                padding: '0.5rem 1rem', 
                                borderRadius: '0.25rem', 
                                transition: 'all 0.3s ease',
                                border: 'none',
                                cursor: 'pointer',
                                transform: 'translateY(0)',
                                boxShadow: 'none'
                            }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#dc2626';
                                e.target.style.transform = 'translateY(-2px)';
                                e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#ef4444';
                                e.target.style.transform = 'translateY(0)';
                                e.target.style.boxShadow = 'none';
                            }}
                        >
                            Logout
                        </button>
                    </div>
                    <div style={{backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '1.5rem'}}>
                        <h2 style={{fontSize: '1.5rem', fontWeight: 600, marginBottom: '1.5rem', color: '#1f2937'}}>Your Grids</h2>
                        {grids.length === 0 ? (
                            <p style={{color: '#4b5563'}}>No saved grids yet. Start creating!</p>
                        ) : (
                            <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '1.5rem'}}>
                                {grids.map((grid) => (
                                    <Link 
                                        key={grid.id} 
                                        to={`/cad/${grid.id}`}
                                        style={gridItemStyle}
                                        onMouseEnter={(e) => Object.assign(e.currentTarget.style, {...gridItemStyle, ...gridItemHoverStyle})}
                                        onMouseLeave={(e) => Object.assign(e.currentTarget.style, gridItemStyle)}
                                    >
                                        <h3 style={{fontWeight: 600, fontSize: '1.25rem', marginBottom: '0.5rem', color: '#4338ca'}}>{grid.name}</h3>
                                        <p style={{fontSize: '0.875rem', color: '#4b5563'}}>Created: {new Date(grid.created_at).toLocaleDateString()}</p>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Dashboard;