import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import './App.css';

// Helper functions
const isCloseEnough = (p1, p2) => {
    const distance = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    return distance < 10;
};

const calculateDistance = (p1, p2, scaleFactor) => {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const pixelDistance = Math.sqrt(dx * dx + dy * dy);
    return pixelDistance * scaleFactor;
};

const formatDistance = (distance) => {
    const feet = Math.floor(distance);
    const inches = Math.round((distance - feet) * 12);
    return `${feet}'${inches}"`;
};

const MapSelector = ({ onLocationSelect, onCancel }) => {
    const mapRef = useRef(null);
    const searchInputRef = useRef(null);
    const scriptRef = useRef(null);
    const imageRef = useRef(null);
    const canvasRef = useRef(null);
    const [cameraMode, setCameraMode] = useState(false);
    const [photoTaken, setPhotoTaken] = useState(null);
    const [lastCenter, setLastCenter] = useState(null);
    const [lastZoom, setLastZoom] = useState(null);
    const [isDetectingRoof, setIsDetectingRoof] = useState(false);
    const [isMapInitialized, setIsMapInitialized] = useState(false);
    const [isPlottingPoints, setIsPlottingPoints] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [currentPolygon, setCurrentPolygon] = useState(null);
    const [polygons, setPolygons] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [measurementUnit, setMeasurementUnit] = useState('ft');
    const [scaleFactor, setScaleFactor] = useState(1);

    useEffect(() => {
        document.body.classList.add('map-selector-active');
        return () => {
            document.body.classList.remove('map-selector-active');
        };
    }, []);

    const initMap = useCallback(() => {
        if (mapRef.current) {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: 0, lng: 0 },
                zoom: 2,
                mapTypeId: 'satellite'
            });

            map.addListener('tilesloaded', () => {
                setIsMapInitialized(true);
            });

            map.addListener('click', (event) => {
                if (isMapInitialized) {
                    const latLng = event.latLng.toJSON();
                    onLocationSelect(latLng);
                }
            });

            const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
            autocomplete.bindTo('bounds', map);
            autocomplete.setFields(['geometry', 'name']);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.error("No details available for input: '" + place.name + "'");
                    return;
                }

                map.setCenter(place.geometry.location);
                map.setZoom(20);
            });

            mapRef.current.mapInstance = map;
        }
    }, [isMapInitialized, onLocationSelect]);

    useEffect(() => {
        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDjVuooG3QamPIdshEvx5sbZ5UUdXDd4co&callback=initMap&libraries=places`;
            script.async = true;
            script.defer = true;
            scriptRef.current = script;
            document.body.appendChild(script);
            script.addEventListener('load', initMap);
        } else {
            initMap();
        }

        return () => {
            if (scriptRef.current) {
                scriptRef.current.remove();
            }
        };
    }, [initMap]);

    const toggleCameraMode = useCallback(() => {
        setCameraMode((prevCameraMode) => !prevCameraMode);
    }, []);

    useEffect(() => {
        const boundBox = document.getElementById('bound-box');
        if (boundBox) {
            boundBox.style.display = cameraMode ? 'block' : 'none';
        }
    }, [cameraMode]);

    const takePhoto = useCallback(() => {
        if (cameraMode && mapRef.current && mapRef.current.mapInstance && isMapInitialized) {
            const map = mapRef.current.mapInstance;
            const center = map.getCenter();
            const zoom = map.getZoom();
            const size = '640x640';
            const mapType = 'satellite';
            const apiKey = 'AIzaSyDjVuooG3QamPIdshEvx5sbZ5UUdXDd4co';

            const photoUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat()},${center.lng()}&zoom=${zoom}&size=${size}&maptype=${mapType}&key=${apiKey}`;

            console.log("Photo URL generated:", photoUrl);
            setPhotoTaken(photoUrl);
            setLastCenter(center);
            setLastZoom(zoom);

            const metersPerPx = 156543.03392 * Math.cos(center.lat() * Math.PI / 180) / Math.pow(2, zoom);
            const feetPerPx = metersPerPx * 3.28084;
            setScaleFactor(feetPerPx);

            setTimeout(() => {
                if (canvasRef.current && imageRef.current) {
                    const ctx = canvasRef.current.getContext('2d');
                    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                    ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                    console.log("Canvas redrawn");
                } else {
                    console.log("Canvas or image ref not available");
                }
            }, 100);
        }
    }, [cameraMode, isMapInitialized]);

    const handleImageLoad = useCallback(() => {
        if (imageRef.current) {
            console.log("Image loaded, dimensions:", imageRef.current.naturalWidth, imageRef.current.naturalHeight);
            setImageDimensions({
                width: imageRef.current.naturalWidth,
                height: imageRef.current.naturalHeight
            });
            
            if (canvasRef.current) {
                const ctx = canvasRef.current.getContext('2d');
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                console.log("Canvas redrawn after image load");
            } else {
                console.log("Canvas ref not available");
            }
        } else {
            console.log("Image ref not available");
        }
    }, []);

    const drawPolygons = useCallback(() => {
        if (!canvasRef.current) return;
    
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    
        if (imageRef.current) {
            ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        }
    
        const drawPolygon = (polygon) => {
            ctx.beginPath();
            ctx.moveTo(polygon.points[0].x, polygon.points[0].y);
            polygon.points.forEach((point, index) => {
                if (index > 0) {
                    ctx.lineTo(point.x, point.y);
                    
                    const prevPoint = polygon.points[index - 1];
                    const midX = (prevPoint.x + point.x) / 2;
                    const midY = (prevPoint.y + point.y) / 2;
                    const distance = calculateDistance(prevPoint, point, scaleFactor);
                    if (distance > 0.5) {
                        const angle = Math.atan2(point.y - prevPoint.y, point.x - prevPoint.x);
                        
                        ctx.save();
                        ctx.translate(midX, midY);
                        ctx.rotate(angle);
                        
                        ctx.font = '12px Arial';
                        const text = measurementUnit === 'ft' ? formatDistance(distance) : `${distance.toFixed(2)}m`;
                        const textWidth = ctx.measureText(text).width;
                        
                        ctx.fillStyle = 'white';
                        ctx.fillRect(-textWidth / 2 - 2, -8, textWidth + 4, 16);
                        ctx.strokeStyle = 'black';
                        ctx.strokeRect(-textWidth / 2 - 2, -8, textWidth + 4, 16);
                        
                        ctx.fillStyle = 'black';
                        ctx.fillText(text, -textWidth / 2, 4);
                        
                        ctx.restore();
                    }
                }
            });
            if (polygon.closed) {
                ctx.closePath();
                ctx.fillStyle = polygon.color + '40';
                ctx.fill();
            }
            ctx.strokeStyle = polygon.color;
            ctx.lineWidth = 2;
            ctx.stroke();
        };
    
        polygons.forEach(drawPolygon);
        if (currentPolygon) drawPolygon(currentPolygon);
    }, [polygons, currentPolygon, measurementUnit, scaleFactor]);

    const handleImageClick = useCallback((event) => {
        if (!isPlottingPoints) {
            return;
        }
    
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
    
        const x = Math.round((event.clientX - rect.left) * scaleX);
        const y = Math.round((event.clientY - rect.top) * scaleY);
    
        if (!isDrawing) {
            setCurrentPolygon({ points: [{ x, y }], color: '#FF0000' });
            setIsDrawing(true);
        } else {
            setCurrentPolygon((prev) => {
                const newPoints = [...prev.points, { x, y }];
                if (isCloseEnough(newPoints[0], { x, y }) && newPoints.length > 2) {
                    const closedPolygon = { ...prev, points: newPoints, closed: true };
                    setPolygons((prevPolygons) => [...prevPolygons, closedPolygon]);
                    setIsDrawing(false);
                    return null;
                }
                return { ...prev, points: newPoints };
            });
        }
        
        drawPolygons();
    }, [isPlottingPoints, isDrawing, drawPolygons]);

    useEffect(() => {
        drawPolygons();
    }, [drawPolygons]);

    useEffect(() => {
        if (photoTaken) {
            drawPolygons();
        }
    }, [photoTaken, drawPolygons]);

    const startManualPointPlotting = useCallback(() => {
        setIsPlottingPoints(true);
        setPolygons([]);
        setCurrentPolygon(null);
        setIsDrawing(false);
    }, []);

    function removeDuplicateShapes(shapes) {
        // Create a map to store unique shapes
        const uniqueShapes = new Map();
    
        shapes.forEach(shape => {
            // Convert the points array to a JSON string for comparison
            const key = JSON.stringify(shape.points);
    
            // Add the shape to the map if it doesn't exist already
            if (!uniqueShapes.has(key)) {
                uniqueShapes.set(key, shape);
            }
        });
    
        // Convert the map back to an array
        return Array.from(uniqueShapes.values());
    }

    const finishManualPointPlotting = useCallback(() => {
        let modifypolygon = removeDuplicateShapes(polygons);

        if (polygons.length === 0) {
            alert("Please create at least one polygon to define the roof outline.");
            return;
        }

        setIsPlottingPoints(false);
        setIsDetectingRoof(true);
        const map = mapRef.current.mapInstance;
        const center = map.getCenter();
        const zoom = map.getZoom();

        const payload = { 
            lat: center.lat(),
            lng: center.lng(),
            center: { lat: center.lat(), lng: center.lng() },
            photoUrl: photoTaken,
            zoom: zoom,
            polygons: modifypolygon.map((polygon) => ({
                // ...polygon,
                points: polygon.points.map((point, index) => ({
                    ...point,
                    distance: index > 0 ? calculateDistance(polygon.points[index - 1], point, scaleFactor) : 0
                }))
            }))
        };

        console.log("Sending payload to parent:", payload);
        onLocationSelect(payload);
    }, [polygons, photoTaken, onLocationSelect, scaleFactor]);

    const undoLastPoint = useCallback(() => {
        setPolygons((prevPolygons) => {
            let updatedPolygons = [...prevPolygons];
            let updatedCurrentPolygon = currentPolygon ? {...currentPolygon} : null;
    
            if (updatedCurrentPolygon) {
                // We're currently drawing a polygon
                if (updatedCurrentPolygon.points.length > 1) {
                    // Remove the last point
                    updatedCurrentPolygon.points.pop();
                    updatedCurrentPolygon.closed = false;
                    setCurrentPolygon(updatedCurrentPolygon);
                } else {
                    // If only one point left, remove the current polygon entirely
                    setCurrentPolygon(null);
                    setIsDrawing(false);
                }
            } else if (updatedPolygons.length > 0) {
                // We're not currently drawing, so modify the last completed polygon
                let lastPolygon = updatedPolygons.pop();
                if (lastPolygon.closed) {
                    // If the polygon is closed, reopen it
                    lastPolygon.closed = false;
                    updatedCurrentPolygon = lastPolygon;
                    setIsDrawing(true);
                } else if (lastPolygon.points.length > 1) {
                    // Remove the last point
                    lastPolygon.points.pop();
                    updatedCurrentPolygon = lastPolygon;
                    setIsDrawing(true);
                } else {
                    // If only one point left, don't add it back to currentPolygon
                    setIsDrawing(false);
                }
                setCurrentPolygon(updatedCurrentPolygon);
            }
    
            // Ensure we're not left with any empty polygons
            updatedPolygons = updatedPolygons.filter(polygon => polygon.points.length > 1);
    
            return updatedPolygons;
        });
    }, [currentPolygon]);

    const handleRetakePhoto = useCallback(() => {
        setPhotoTaken(null);
        setPolygons([]);
        setCurrentPolygon(null);
        setIsPlottingPoints(false);
        setIsDrawing(false);
        if (mapRef.current && mapRef.current.mapInstance && lastCenter && lastZoom) {
            const map = mapRef.current.mapInstance;
            map.setCenter(lastCenter);
            map.setZoom(lastZoom);
        }
    }, [lastCenter, lastZoom]);

    const handleMouseMove = useCallback((event) => {
        if (!isPlottingPoints || !isDrawing || !currentPolygon) return;
    
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
    
        const x = Math.round((event.clientX - rect.left) * scaleX);
        const y = Math.round((event.clientY - rect.top) * scaleY);
    
        drawPolygons();
    
        const ctx = canvasRef.current.getContext('2d');
        const lastPoint = currentPolygon.points[currentPolygon.points.length - 1];
        ctx.beginPath();
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(x, y);
        ctx.strokeStyle = currentPolygon.color;
        ctx.lineWidth = 2;
        ctx.stroke();
    
        const midX = (lastPoint.x + x) / 2;
        const midY = (lastPoint.y + y) / 2;
        const distance = calculateDistance(lastPoint, { x, y }, scaleFactor);
        if (distance > 0.5) {
            const angle = Math.atan2(y - lastPoint.y, x - lastPoint.x);
        
            ctx.save();
            ctx.translate(midX, midY);
            ctx.rotate(angle);
        
            ctx.font = '12px Arial';
            const text = measurementUnit === 'ft' ? formatDistance(distance) : `${distance.toFixed(2)}m`;
            const textWidth = ctx.measureText(text).width;
        
            ctx.fillStyle = 'white';
            ctx.fillRect(-textWidth / 2 - 2, -8, textWidth + 4, 16);
            ctx.strokeStyle = 'black';
            ctx.strokeRect(-textWidth / 2 - 2, -8, textWidth + 4, 16);
        
            ctx.fillStyle = 'black';
            ctx.fillText(text, -textWidth / 2, 4);
        
            ctx.restore();
        }
    }, [isPlottingPoints, isDrawing, currentPolygon, drawPolygons, calculateDistance, measurementUnit, scaleFactor]);
    
    const toggleMeasurementUnit = useCallback(() => {
        setMeasurementUnit((prev) => prev === 'ft' ? 'm' : 'ft');
    }, []);

    return (
        <div className="map-selector-container">
            <div ref={mapRef} className="map-container" />
            <div className="search-container">
                <input 
                    ref={searchInputRef}
                    type="text" 
                    placeholder="Search location" 
                    className="search-input"
                />
            </div>
            <div id="bound-box" className="bound-box" style={{ display: 'none' }}></div>
            <button id="camera-button" onClick={toggleCameraMode}>
                {cameraMode ? '📷' : '🗺️'}
            </button>
            {cameraMode && (
                <button onClick={takePhoto} className="take-photo-button">
                    Take Photo
                </button>
            )}
            {photoTaken && (
                <div className="photo-preview">
                    <img 
                        ref={imageRef}
                        src={photoTaken} 
                        alt="Captured location" 
                        onLoad={handleImageLoad}
                        style={{ display: 'none' }}
                        crossOrigin="anonymous"
                    />
                    <canvas 
                        ref={canvasRef}
                        width={imageDimensions.width || 640}
                        height={imageDimensions.height || 640}
                        onClick={handleImageClick}
                        onMouseMove={handleMouseMove}
                        style={{ cursor: isPlottingPoints ? 'crosshair' : 'default' }}
                    />
                    {isDetectingRoof && (
                        <div className="detecting-roof">
                            <div className="spinner"></div>
                            <p>Detecting roof outline...</p>
                        </div>
                    )}
                    {!isDetectingRoof && !isPlottingPoints && (
                        <div className="photo-preview-buttons">
                            <button 
                                className="accept-button" 
                                onClick={startManualPointPlotting}
                            >
                                Plot Roof Points
                            </button>
                            <button className="retake-button" onClick={handleRetakePhoto}>Retake</button>
                        </div>
                    )}
                    {isPlottingPoints && (
                        <div className="photo-preview-buttons">
                            <button 
                                className="accept-button" 
                                onClick={finishManualPointPlotting}
                            >
                                Finish Plotting
                            </button>
                            <button className="retake-button" onClick={handleRetakePhoto}>Retake</button>
                            <button className="undo-button" onClick={undoLastPoint}>Undo Last Point</button>
                        </div>
                    )}
                    {isPlottingPoints && (
                        <div style={{
                            position: 'absolute',
                            top: 10,
                            left: 10,
                            background: 'rgba(255, 255, 255, 0.8)',
                            padding: '5px 10px',
                            borderRadius: '5px'
                        }}>
                            Plotting Mode Active
                        </div>
                    )}
                    <button 
                        onClick={toggleMeasurementUnit} 
                        className="unit-toggle-button"
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            padding: '5px 10px',
                            background: 'white',
                            border: '1px solid black',
                            borderRadius: '5px'
                        }}
                    >
                        {measurementUnit === 'ft' ? 'Switch to Meters' : 'Switch to Feet'}
                    </button>
                </div>
            )}
            <button id="cancel-button" onClick={onCancel}>Cancel</button>
        </div>
    );
};

export default MapSelector;