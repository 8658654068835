import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './store';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient('https://vpwkvuwpssblzjaxpjbr.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZwd2t2dXdwc3NibHpqYXhwamJyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMwOTI2NTcsImV4cCI6MjAzODY2ODY1N30.S_Rg5WmFbpIe7-pzhD7RtFzQ69HVHFhVj_4Wg92bk_c');

const AuthPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        const checkUser = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            if (user) {
                dispatch(setUser(user));
                navigate('/dashboard');
            }
        };
        checkUser();
    }, [dispatch, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: email,
                password: password,
            });

            if (error) throw error;

            dispatch(setUser(data.user));
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error);
            setError('Invalid email or password');
        }
    };

    if (user) {
        navigate('/dashboard');
        return null;
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            background: 'linear-gradient(to bottom, #4338ca, #3730a3)',
            fontFamily: "'Arial', sans-serif"
        }}>
            <div style={{
                width: '100%',
                maxWidth: '400px',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                padding: '2rem'
            }}>
                <h2 style={{
                    fontSize: '1.875rem',
                    fontWeight: 'bold',
                    marginBottom: '1.5rem',
                    textAlign: 'center',
                    color: '#1f2937'
                }}>
                    DynamicDrafts Login
                </h2>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                    <div>
                        <label htmlFor="email" style={{display: 'block', marginBottom: '0.5rem', color: '#4b5563', fontSize: '0.875rem'}}>
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '0.5rem',
                                borderRadius: '0.25rem',
                                border: '1px solid #d1d5db',
                                fontSize: '1rem'
                            }}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" style={{display: 'block', marginBottom: '0.5rem', color: '#4b5563', fontSize: '0.875rem'}}>
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '0.5rem',
                                borderRadius: '0.25rem',
                                border: '1px solid #d1d5db',
                                fontSize: '1rem'
                            }}
                            required
                        />
                    </div>
                    {error && <p style={{color: '#ef4444', fontSize: '0.875rem', marginTop: '0.5rem'}}>{error}</p>}
                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            padding: '0.75rem',
                            borderRadius: '0.25rem',
                            backgroundColor: '#4338ca',
                            color: 'white',
                            fontSize: '1rem',
                            fontWeight: '500',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.2s',
                            ':hover': {backgroundColor: '#3730a3'}
                        }}
                    >
                        Log In
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AuthPage;