import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthPage from './AuthPage';
import Dashboard from './Dashboard';
import CADApp from './CADApp';
import AccountPage from './AccountPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<AuthPage />} />
      <Route path="/login" element={<AuthPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/cad" element={<CADApp />} />
      <Route path="/cad/:gridId" element={<CADApp />} />
      <Route path="/account" element={<AccountPage />} />
    </Routes>
  );
}

export default App;