import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { setUser } from './store';
import { ReactComponent as DynamicDraftsLogo } from './DynamicDraftsLogo.svg';

const AccountPage = () => {
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [subscriptionType, setSubscriptionType] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [nextBillingDate, setNextBillingDate] = useState('');
    const [isLogoHovered, setIsLogoHovered] = useState(false);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            dispatch(setUser(parsedUser));
            setName(parsedUser.name || '');
            setEmail(parsedUser.email || '');
            // Simulated subscription data
            setSubscriptionStatus('Active');
            setSubscriptionType('Premium');
            setPaymentMethod('Visa ending in 1234');
            setNextBillingDate('2023-09-01');
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage('');

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const updatedUser = {
            ...user,
            name: name,
            email: email
        };

        if (password) {
            updatedUser.password = password;  // In reality, never store plain text passwords
        }

        localStorage.setItem('user', JSON.stringify(updatedUser));
        dispatch(setUser(updatedUser));
        setSuccessMessage('Account updated successfully');
    };

    const handleCancelSubscription = () => {
        // Simulated cancellation logic
        setSubscriptionStatus('Cancelled');
        setSuccessMessage('Subscription cancelled successfully. Your access will continue until the end of the billing period.');
    };

    const handleUpdatePaymentMethod = () => {
        // Simulated payment method update logic
        setPaymentMethod('Mastercard ending in 5678');
        setSuccessMessage('Payment method updated successfully.');
    };

    if (!user) {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f3f4f6'}}>
            <div style={{fontSize: '1.5rem', fontWeight: 600, color: '#1f2937'}}>Loading...</div>
        </div>;
    }

    const logoLinkStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        transition: 'all 0.3s ease',
        textDecoration: 'none',
        color: 'white',
    };

    const logoStyle = {
        width: '100%',
        height: 'auto',
        transition: 'transform 0.3s ease',
    };

    return (
        <div style={{display: 'flex', height: '100vh', backgroundColor: '#f3f4f6'}}>
            <aside style={{width: '16rem', background: 'linear-gradient(to bottom, #4338ca, #3730a3)', color: 'white'}}>
                <div style={{padding: '1.5rem'}}>
                    <Link 
                        to="/dashboard" 
                        style={{
                            ...logoLinkStyle,
                            transform: isLogoHovered ? 'scale(1.05)' : 'scale(1)',
                            boxShadow: isLogoHovered ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                            backgroundColor: isLogoHovered ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                        }}
                        onMouseEnter={() => setIsLogoHovered(true)}
                        onMouseLeave={() => setIsLogoHovered(false)}
                    >
                        <DynamicDraftsLogo style={logoStyle} />
                        <span style={{
                            marginTop: '0.5rem',
                            fontSize: '0.875rem',
                            opacity: isLogoHovered ? 1 : 0,
                            transition: 'opacity 0.3s ease',
                        }}>
                            Back to Dashboard
                        </span>
                    </Link>
                </div>
            </aside>
            <main style={{flex: 1, overflowY: 'auto', padding: '2rem'}}>
                <div style={{maxWidth: '48rem', margin: '0 auto'}}>
                    <div style={{backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '2rem', marginBottom: '2rem'}}>
                        <h2 style={{fontSize: '1.875rem', fontWeight: 'bold', marginBottom: '1.5rem', color: '#1f2937', textAlign: 'center'}}>Account Settings</h2>
                        {error && <p style={{color: '#ef4444', marginBottom: '1rem'}}>{error}</p>}
                        {successMessage && <p style={{color: '#10b981', marginBottom: '1rem'}}>{successMessage}</p>}
                        <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                            <div>
                                <label htmlFor="name" style={{display: 'block', marginBottom: '0.5rem', color: '#4b5563', fontSize: '0.875rem'}}>Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #d1d5db'}}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="email" style={{display: 'block', marginBottom: '0.5rem', color: '#4b5563', fontSize: '0.875rem'}}>Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #d1d5db'}}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password" style={{display: 'block', marginBottom: '0.5rem', color: '#4b5563', fontSize: '0.875rem'}}>New Password (leave blank to keep current)</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #d1d5db'}}
                                />
                            </div>
                            <div>
                                <label htmlFor="confirmPassword" style={{display: 'block', marginBottom: '0.5rem', color: '#4b5563', fontSize: '0.875rem'}}>Confirm New Password</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    style={{width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #d1d5db'}}
                                />
                            </div>
                            <button
                                type="submit"
                                style={{
                                    backgroundColor: '#4338ca',
                                    color: 'white',
                                    padding: '0.75rem',
                                    borderRadius: '0.25rem',
                                    fontWeight: '600',
                                    transition: 'background-color 0.2s',
                                    ':hover': {backgroundColor: '#3730a3'}
                                }}
                            >
                                Update Account
                            </button>
                        </form>
                    </div>
                    <div style={{backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '2rem'}}>
                        <h2 style={{fontSize: '1.875rem', fontWeight: 'bold', marginBottom: '1.5rem', color: '#1f2937', textAlign: 'center'}}>Subscription Management</h2>
                        <div style={{marginBottom: '1rem'}}>
                            <p><strong>Status:</strong> {subscriptionStatus}</p>
                            <p><strong>Type:</strong> {subscriptionType}</p>
                            <p><strong>Payment Method:</strong> {paymentMethod}</p>
                            <p><strong>Next Billing Date:</strong> {nextBillingDate}</p>
                        </div>
                        <div style={{display: 'flex', gap: '1rem', justifyContent: 'center'}}>
                            <button
                                onClick={handleUpdatePaymentMethod}
                                style={{
                                    backgroundColor: '#10b981',
                                    color: 'white',
                                    padding: '0.75rem',
                                    borderRadius: '0.25rem',
                                    fontWeight: '600',
                                    transition: 'background-color 0.2s',
                                    ':hover': {backgroundColor: '#059669'}
                                }}
                            >
                                Update Payment Method
                            </button>
                            <button
                                onClick={handleCancelSubscription}
                                style={{
                                    backgroundColor: '#ef4444',
                                    color: 'white',
                                    padding: '0.75rem',
                                    borderRadius: '0.25rem',
                                    fontWeight: '600',
                                    transition: 'background-color 0.2s',
                                    ':hover': {backgroundColor: '#dc2626'}
                                }}
                            >
                                Cancel Subscription
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AccountPage;