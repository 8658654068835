import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://vpwkvuwpssblzjaxpjbr.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZwd2t2dXdwc3NibHpqYXhwamJyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMwOTI2NTcsImV4cCI6MjAzODY2ODY1N30.S_Rg5WmFbpIe7-pzhD7RtFzQ69HVHFhVj_4Wg92bk_c';
const supabase = createClient(supabaseUrl, supabaseKey);

export const fetchGrids = createAsyncThunk(
  'grid/fetchGrids',
  async (userId) => {
    const { data, error } = await supabase
      .from('grids')
      .select('*')
      .eq('user_id', userId);

    if (error) {
      throw new Error(error.message);
    }
    return data;
  }
);

export const addGrid = createAsyncThunk(
  'grid/addGrid',
  async (grid) => {
    const { data, error } = await supabase
      .from('grids')
      .insert([grid])
      .single();

    if (error) {
      throw new Error(error.message);
    }
    return data;
  }
);

export const updateGrid = createAsyncThunk(
  'grid/updateGrid',
  async (grid) => {
    const { data, error } = await supabase
      .from('grids')
      .update(grid)
      .eq('id', grid.id)
      .single();

    if (error) {
      throw new Error(error.message);
    }
    return data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

const gridSlice = createSlice({
  name: 'grid',
  initialState: {
    grids: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGrids.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGrids.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.grids = action.payload;
      })
      .addCase(fetchGrids.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addGrid.fulfilled, (state, action) => {
        state.grids.push(action.payload);
      })
      .addCase(updateGrid.fulfilled, (state, action) => {
        const index = state.grids.findIndex(grid => grid.id === action.payload.id);
        if (index !== -1) {
          state.grids[index] = action.payload;
        }
      });
  },
});

export const { setUser, clearUser } = authSlice.actions;

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    grid: gridSlice.reducer,
  },
});

export default store;